import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import {
    Box,
    Typography,
    IconButton,
    Container,
    Toolbar,
    Menu,
    MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramnIcon from '@mui/icons-material/Instagram';

const pages = ['Home', 'About', 'Services', 'Blogs', 'Pricing'];

const pagesRoutes = {
    Home: '/',
    About: '/about',
    Services: '/services',
    Blogs: '/blogs',
    Pricing: '/pricing',
};

const SOCIAL_LINKS = {
    facebook: 'https://www.facebook.com/profile.php?id=61558429400107',
    linkedin: 'http://www.linkedin.com/in/topmedia-marketing-4803a3303',
    instagram: 'https://www.instagram.com/topmediamarketing.ca?igsh=YXk3NjdhN212ZHAx',
};

function Header() {
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const iconButtonStyle = {
        color: 'grey',
        backgroundColor: 'transparent',
        '&:hover': {
            color: '#800080',
            backgroundColor: 'transparent',
        },
    };

    const handleButtonClick = (name) => {
        window.open(SOCIAL_LINKS[name], '_blank');
    };

    return (
        <AppBar
            position="static"
            sx={{ backgroundColor: 'white', boxShadow: 'none', padding: '10px 0' }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Logo */}
                    <Box sx={{ flexGrow: 1 }}>
                        <Link to="/">
                            <img
                                src={logo}
                                alt="top-media-logo"
                                style={{ width: '190px', height: '50px' }}
                            />
                        </Link>
                    </Box>

                    {/* Menu icon for mobile */}
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton
                            size="large"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color='#808080'
                            sx={{ display: { xs: 'flex', md: 'none' }, ml: -2 }} // Adjust ml for alignment
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    {/* Navigation links */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        {pages.map((page) => (
                            <Typography
                                key={page}
                                component={Link}
                                to={pagesRoutes[page]}
                                onClick={handleCloseNavMenu}
                                sx={{
                                    my: 2,
                                    mx: 2,
                                    display: 'block',
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                    color: location.pathname === pagesRoutes[page] ? '#800080' : 'black',
                                    fontWeight: '600',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: '#800080',
                                        borderBottom: "2px solid #800080"
                                    },
                                }}
                            >
                                {page}
                            </Typography>
                        ))}
                        <Box
                            sx={{
                                '& a': {
                                    padding: '2px',
                                    display: 'block',
                                    fontSize: '16px',
                                    color: 'black',
                                    fontWeight: "600",
                                    textDecoration: "none",
                                    marginLeft: '10px',
                                    '&:hover': {
                                        color: "#800080",
                                        borderBottom: "2px solid #800080"
                                    },
                                },
                            }}
                        >
                            <a href="https://calendly.com/toprealtorslimited/free-consultation">
                                Contact Us
                            </a>
                        </Box>

                        {/* Separator */}
                        <Typography
                            variant="body1"
                            sx={{
                                mx: 2,
                                color: 'grey',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            |
                        </Typography>

                        {/* Social media icons */}
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <IconButton
                                aria-label="facebook"
                                sx={iconButtonStyle}
                                onClick={() => handleButtonClick('facebook')}
                            >
                                <FacebookIcon />
                            </IconButton>
                            <IconButton aria-label="linkedin" sx={iconButtonStyle} onClick={() => handleButtonClick('linkedin')}>
                                <LinkedInIcon />
                            </IconButton>
                            <IconButton aria-label="instagram" sx={iconButtonStyle} onClick={() => handleButtonClick('instagram')}>
                                <InstagramnIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>

                {/* Mobile menu */}
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        mt: '65px',
                        width: '100vw',
                        padding: '0px', // Padding inside the menu for spacing
                        backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent background
                        backdropFilter: 'blur(10px)', // iOS-style blur effect
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a shadow for depth
                        '& .MuiMenuItem-root': { // Apply styles to each menu item
                            fontSize: '16px',
                            padding: '12px 20px', // Increased vertical padding for better spacing
                            color: '#333',
                            borderBottom: '1px solid #ccc', // Keep the border for separation
                            '&:last-child': {
                                borderBottom: 'none', // Remove the bottom border for the last item
                            },
                            '.MuiPaper-root': {
                                minWidth: '100vw', // Ensure the paper stretches to full width
                                maxWidth: '100%', // Prevent any maxWidth from constraining the paper element
                            },
                        },
                        //   '& .MuiIconButton-root': {
                        //       margin: '0 5px', // Increase spacing around icons
                        //   },
                    }}
                >
                    {pages.map((page) => (
                        <MenuItem
                            key={page}
                            component={Link}
                            to={pagesRoutes[page]}
                            onClick={handleCloseNavMenu}
                            sx={{
                                minWidth: '100vw', // Ensure menu items are full width
                                fontSize: '16px',
                                textAlign: 'center',
                                color: '#333', // Change text color
                                borderBottom: '1px solid #ccc', // Add bottom border
                            }}
                        >
                            {page}
                        </MenuItem>
                    ))}
                    <MenuItem
                        component="a"
                        href="https://calendly.com/toprealtorslimited/free-consultation"
                        onClick={handleCloseNavMenu}
                        sx={{
                            fontSize: '16px',
                            textAlign: 'center',
                            color: '#333', // Change text color
                            borderBottom: '1px solid #ccc', // Add bottom border
                        }}
                    >
                        Contact Us
                    </MenuItem>

                    {/* Social media icons */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: '10px' }}>
                        <IconButton
                            aria-label="facebook"
                            sx={iconButtonStyle}
                            onClick={() => handleButtonClick('facebook')}
                        >
                            <FacebookIcon />
                        </IconButton>
                        <IconButton aria-label="linkedin" sx={iconButtonStyle} onClick={() => handleButtonClick('linkedin')}>
                            <LinkedInIcon />
                        </IconButton>
                        <IconButton aria-label="instagram" sx={iconButtonStyle} onClick={() => handleButtonClick('instagram')}>
                            <InstagramnIcon />
                        </IconButton>
                    </Box>
                </Menu>
            </Container>
        </AppBar>
    );
}

export default Header;
