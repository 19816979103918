import React from "react";
import { Typography, Box, Grid, Button, Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import socialMediaMArketingImage from "../assets/social-media-marketing-service.jpg";

const AnimatedBox = animated(Box);

export default function SocialMediaMarketingService() {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });

    const fadeInUpAnimation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s, transform 0.5s",
    });

    return (
        <Box sx={{ padding: "20px", marginTop: "20px", marginBottom: "40px" }}>
            <Box sx={{ width: "90%", margin: "auto", paddingTop: "40px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ref={ref}>
                        <AnimatedBox style={fadeInUpAnimation}>
                            <Typography
                                sx={{
                                    fontSize: { xs: "30px", md: "48px" },
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                    background: `linear-gradient(to right, #530068, black)`,
                                    WebkitBackgroundClip: "text",
                                    color: "transparent",
                                    lineHeight: "1.2",
                                }}
                            >
                                Social Media Marketing
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "16px", md: "20px" }, }}>
                                In today's digital landscape, social media marketing plays a pivotal role in driving business growth. Our proficient team specializes in leveraging social platforms to generate high-quality leads, foster engagement, conduct market research, and manage databases, ensuring your brand's dominance in the online sphere. With our experienced team and efficient workflow, we elevate your social media presence to new heights.
                            </Typography>
                            <Box sx={{ padding: "30px 0px" }}>
                                <h3 style={{
                                    color: "#530068"
                                }}>Experienced in :</h3>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* Checklist Item 1 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Website Design
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 2 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Domain Hosting
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 3 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Social Media Management
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                SEO (Search Engine Optimization)
                                            </Typography>
                                        </Box>


                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Online Advertising
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Sales Funnels
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                GMB Management (Google My Business)
                                            </Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Online Reputation Management
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Branding
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 5 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                ROI Tracking and Reporting
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 6 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                A.I Software Integration
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Appointment Scheduling and Calendar Integration
                                            </Typography>
                                        </Box>

                                        
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                And much more, including expertise in Meta platforms (such as Facebook) and Google Ads.
                                            </Typography>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                            <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                                <Button
                                    type="danger"
                                    sx={{
                                        backgroundColor: "#530068",
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "15px 30px",
                                        ':hover': {
                                            backgroundColor: "#800080 !important",
                                        },
                                    }}
                                >
                                    Join over 400 Realtors that trust us!
                                </Button>
                            </Link>
                        </AnimatedBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box>
                            <img
                                src={socialMediaMArketingImage}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "540px",
                                    objectFit: "contain", // or "contain" depending on your preference
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

