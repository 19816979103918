import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import CallcenterAndCallingIcon from "../assets/call-center-and-calling.png";
import VARealEstateIcon from "../assets/va-real-estate.png";
import CustomerSupportIcon from "../assets/customer-support.png";
import RealEstateColdCallingIcon from "../assets/real-estate-cold-calling.png";
import SocialMediaMarketingIcon from "../assets/social-media-marketing.png";

import '../styles/ServicesContainer.css';

export default function ServicesContainer() {
    const services = [
        {
            title: 'Call Center and Calling',
            description: 'Stay ahead in real estate with our expert team\'s efficient cold calling strategies for quality leads, appointments, and database management.',
            icon: CallcenterAndCallingIcon,
            link: '/call-center',
            hoverColor: "#47AEFF"
        },
        {
            title: 'Virtual Assistant for Real Estate Business',
            description: 'Elevate your real estate business with our tailored virtual assistant services, freeing your time for growth.',
            icon: VARealEstateIcon,
            link: '/virtual-assistant',
            hoverColor: "#FFA76E"
        },
        {
            title: 'Customer Support Services',
            description: 'Elevate your customer support with our top-tier representatives, delivering savings and efficiency to large companies.',
            icon: CustomerSupportIcon,
            link: '/customer-support',
            hoverColor: "#E80368"
        },
        {
            title: 'Real Estate Cold Calling',
            description: 'Boost your real estate leads and revenues with skilled cold calling VAs, enhancing your sales pipeline without exceeding costs.',
            icon: RealEstateColdCallingIcon,
            link: '/real-estate-cold-calling',
            hoverColor: "#FFBB2C"
        },
        {
            title: 'Social Media Marketing',
            description: 'We excel in outsourcing quality support reps, providing financial benefits. With 200 experienced cold calling professionals in Pakistan, we\'re ready to enhance your call center.',
            icon: SocialMediaMarketingIcon,
            link: '/social-media-marketing',
            hoverColor: "#FF5828"
        },
    ];

    return (
        <div className="app">
            <div className="services-hero">
                <h2>Services We Offer</h2>
            </div>

            <div className="service-container">
                {services.map((service, index) => (
                    <div className="service-card" key={index}>
                        <img src={service.icon} alt={service.title} className="hover-color" style={{ '--hover-color': service.hoverColor }} />
                        <Link to={service.link} className="service-link">
                            <h4 className="service-title">{service.title}</h4>
                        </Link>
                        <p style={{ color: "#5B5552" }}>{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
