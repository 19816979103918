import React from "react";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import blog1 from "../assets/blog1.jpeg";
import blog2 from "../assets/blog2.jpeg";
import blog3 from "../assets/blog3.jpeg";
import EastIcon from "@mui/icons-material/East";

export default function Companies() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 700},
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    return (
        <Box
            sx={{
                paddingBottom: "40px",
                paddingTop: "85px",
                width: "90%",
                margin: "auto",
            }}
        >
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            >
                <div style={{ margin: "10px" }}>
                    <RouterLink to="/what-is-digital-marketing?">

                        <Box
                            sx={{
                                border: "1px solid gray",
                                padding: "20px",
                                borderRadius: "20px",

                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                    color: "#000",
                                }}
                            >
                                What is a digital Marketing Company ?
                            </Typography>


                            <img src={blog1} alt="" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                            <Box>
                            </Box>
                            <Box sx={{ display: "flex", marginTop: "20px", color: "#000" }}>
                                <Typography>Check it out </Typography>
                                <EastIcon style={{ color: "lightblue", marginLeft: "20px" }} />
                            </Box>
                        </Box>
                    </RouterLink>
                </div>

                <div style={{ margin: "10px" }}>
                    <RouterLink to="/digital-egency-worth?">

                        <Box
                            sx={{
                                border: "1px solid gray",
                                padding: "20px",
                                borderRadius: "20px",
                            }}
                        >
                            <Typography sx={{ fontWeight: "600", marginBottom: "10px", color: "#000" }}>
                                Digital Agency Worth for Real Estate ?
                            </Typography>
                            <img src={blog2} alt="" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                            <Box>
                            </Box>
                            <Box sx={{ display: "flex", marginTop: "20px", color: "#000" }}>
                                <Typography>Check it out </Typography>
                                <EastIcon style={{ color: "lightblue", marginLeft: "20px" }} />
                            </Box>
                        </Box>
                    </RouterLink>


                </div>

                <div style={{ margin: "10px" }}>
                    <RouterLink to="/does-digital-marketing-work-for?">

                        <Box
                            sx={{
                                border: "1px solid gray",
                                padding: "20px",
                                borderRadius: "20px",
                            }}
                        >
                            <Typography sx={{ fontWeight: "600", marginBottom: "10px", color: "#000" }}>
                                Does Digital Marketing work for Real Estate ?
                            </Typography>
                            <img src={blog3} alt="" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                            <Box>
                            </Box>
                            <Box sx={{ display: "flex", marginTop: "20px", color: "#000" }}>
                                <Typography>Check it out </Typography>
                                <EastIcon style={{ color: "lightblue", marginLeft: "20px" }} />
                            </Box>
                        </Box>
                    </RouterLink>

                </div>


            </Carousel>
            <Box sx={{ textAlign: "center", marginTop: "40px" }}>
                <MuiLink href="https://calendly.com/toprealtorslimited/free-consultation"
                    sx={{
                        background: `linear-gradient(to right, #530068, black)`,
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                        fontSize: { xs: "30px", md: "36px" },
                        fontWeight: "600",
                        lineHeight: 1.5,
                        textDecoration: "none"
                    }}

                >
                    Book your FREE 1-on-1 Consultation Call with our Marketing Specialists
                </MuiLink>
                <Typography sx={{ textAlign: "center", paddingTop: "20px" }}>
                    In the best-case scenario, our consultation propels your business to new heights. At the very least, you'll gain valuable insights from an industry expert at no cost. Don't miss out on this chance for growth and learning!

                </Typography>
            </Box>
        </Box>
    );
}
