import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Blogs from "./pages/Blogs";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy"

import Service1 from "./components/Service1";
import Service2 from "./components/Service2";
import Service3 from "./components/Service3";
import Service4 from "./components/Service4";
import Service5 from "./components/Service5";

import Blog1 from "./components/Blog1";
import Blog2 from "./components/Blog2";
import Blog3 from "./components/Blog3";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Services Routes */}
        <Route path="/call-center" element={<>
          <Header />
          <Service1 />
          <Footer />
        </>} />
        <Route path="/virtual-assistant" element={<>
          <Header />
          <Service2 />
          <Footer />
        </>} />
        <Route path="/customer-support" element={<>
          <Header />
          <Service3 />
          <Footer />
        </>} />
        <Route path="/real-estate-cold-calling" element={<>
          <Header />
          <Service4 />
          <Footer />
        </>} />
        <Route path="/social-media-marketing" element={<>
          <Header />
          <Service5 />
          <Footer />
        </>} />


       {/* Blogs routes */}
        <Route path="/what-is-digital-marketing?" element={<>
          <Header />
          <Blog1 />
          <Footer />
        </>} />
        <Route path="/digital-egency-worth?" element={<>
          <Header />
          <Blog2 />
          <Footer />
        </>} />
        <Route path="/does-digital-marketing-work-for?" element={<>
          <Header />
          <Blog3 />
          <Footer />
        </>} />
      </Routes>

    </>
  );
};

export default App;
