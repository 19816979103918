import React from "react";
import { Typography, Box } from "@mui/material";

export default function Ecosystem() {
    return (
        <Box sx={{ backgroundColor: "#f7f7f7", padding: "20px 0px" }}>
            <Box sx={{ width: "80%", margin: "auto" }}>
                <Box sx={{ textAlign: "center", margin: "40px 0px" }}>
                    <Typography sx={{ fontSize: "36px", fontWeight: "200" }}>
                        Try our system, risk FREE <free></free>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {xs:"30px" , md:"40px"},
                            fontWeight: "600",
                            background: `linear-gradient(to right, #530068, black)`,
                            WebkitBackgroundClip: "text",
                            color: "transparent",
                            lineHeight: "1.2",
                        }}
                    >
                        {" "}
                        We Guarantee listings or you don't pay!{" "}
                    </Typography>
                    <Typography sx={{ fontSize:"20px" , marginTop:"20px" }}>
                        What are you waiting for? Join over 471 Realtors that use our
                        services to elevate their business!
          </Typography>


                </Box>
            </Box>
        </Box >
    );
}
