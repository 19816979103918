import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ServicesContainer from "../components/ServicesContainer";

export default function Services() {
    return (
        <Box>
            <Header />
            <ServicesContainer />
            <Footer />
        </Box>

    );
}
