import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import blog1 from "../assets/blog1.jpeg";

const Blog2 = () => {
    return (
        <Box sx={{ backgroundColor: "", padding: "67px 0px" }}>

            <Box sx={{ width: "90%", margin: "auto" }}>
                <Box sx={{ marginBottom: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                            <Typography sx={{ fontWeight: "600" }}>Is it Worth Hiring a Digital Marketing Agency for Real Estate?</Typography>
                            <Typography sx={{ fontWeight: "400", color: "grey" }}>Posted last week</Typography>
                            <Typography sx={{ fontWeight: "400", paddingBottom: "10px", paddingTop: "10px" }}>
                                In the fast-paced world of real estate, standing out in the digital marketplace is not just a benefit, it's a necessity. This is where the expertise of a digital marketing agency becomes invaluable. Agencies bring a wealth of knowledge and an arsenal of specialized tools to the table—resources that might not be available in-house. They are well-versed in the latest marketing trends and can tailor strategies to fit the unique needs of a real estate business.

                                By partnering with a digital marketing agency, real estate professionals can tap into advanced SEO techniques, compelling content marketing, targeted social media campaigns, and data-driven PPC advertising. Agencies also offer robust analytics to track campaign performance, allowing for agile adjustments to maximize results.

                                Hiring a digital marketing agency can lead to improved lead generation, enhanced brand awareness, and a broader online presence. These improvements can translate into a more significant return on investment. For real estate agents and brokers, whose success often relies on visibility and reach, a digital marketing agency can be the key to unlocking new growth opportunities.

                                In conclusion, if you're seeking to elevate your real estate business in the digital domain and wondering if it's worth investing in a digital marketing agency, the answer is a resounding yes. The right agency can serve as an extension of your team, driving your marketing efforts forward and allowing you to focus on what you do best—selling properties.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img src={blog1} alt="" style={{ width: "100%", objectFit: "contain" }} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box >
    )
}

export default Blog2