import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogDetails from "../components/BlogDetails";


export default function Blogs() {
    return (
        <Box>
            <Header />
            <BlogDetails />
            <Footer />
        </Box>

    );
}
