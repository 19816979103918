import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';
import Logo from "../assets/logo.png";
import RightArrow from "../assets/arrow-point-to-right.png";

const pages = ['Home', 'About', 'Services', 'Blogs', 'Pricing', 'Contact Us', 'Privacy Policy'];
const pagesRoutes = {
  Home: '/',
  About: '/about',
  Services: '/services',
  Blogs: '/blogs',
  Pricing: '/pricing',
  'Contact Us': 'https://calendly.com/toprealtorslimited/free-consultation',
  'Privacy Policy': '/privacy-policy'
};

const services = ['Call Center and Calling', 'Virtual Assistant for Real Estate Business', 'Customer Support Services', 'Real Estate Cold Calling', 'Social Media Marketing'];
const servicesRoutes = {
  'Call Center and Calling': '/call-center',
  'Virtual Assistant for Real Estate Business': '/virtual-assistant',
  'Customer Support Services': '/customer-support',
  'Real Estate Cold Calling': '/real-estate-cold-calling',
  'Social Media Marketing': '/social-media-marketing',
};

const iconButtonStyle = {
  color: 'grey',
  backgroundColor: 'transparent',
  '&:hover': {
    color: '#800080',
    backgroundColor: 'transparent',
  },
};

const SOCIAL_LINKS = {
    facebook: 'https://www.facebook.com/profile.php?id=61558429400107',
    linkedin: 'http://www.linkedin.com/in/topmedia-marketing-4803a3303',
    instagram: 'https://www.instagram.com/topmediamarketing.ca?igsh=YXk3NjdhN212ZHAx',
};
const handleButtonClick = (name) => {
  window.open(SOCIAL_LINKS[name], '_blank');
};

const Footer = () => {
  return (
    <footer>
      {/* 1st row or section */}
      <div
        style={{
          backgroundColor: '#1E1E1E', // slightly lighter black
          color: 'white',
          padding: '50px',
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        {/* 1st column */}
        <div style={{ marginRight: '20px' }}>
          <img src={Logo} alt="Company Logo" style={{ marginBottom: '10px' }} />
          <div style={{ width: '400px'}}>
            <p style={{ marginBottom: '10px', color: '#d6d6d6' }}>At Top Media Marketing, we understand the intricacies of real estate marketing and the value of quality customer interactions. With over a decade of experience, we offer not just services but solutions that drive growth, enhance productivity, and ensure your business's success in the competitive landscape.</p>
          </div>
          <p><i className="fa fa-envelope" aria-hidden="true" style={{ marginRight: '10px', color: 'grey'}}></i><a href="mailto:Khalid@topmediamarketing.ca" style={{ textDecoration: 'none', color: 'grey' }} onMouseEnter={(e) => { e.target.style.color = '#d6d6d6'; }} onMouseLeave={(e) => { e.target.style.color = 'grey'; }}>khalid@topmediamarketing.ca</a></p>
        </div>
        {/* 2nd column */}
        <div style={{ marginRight: '20px' }}>
          <h4 style={{ marginBottom: '10px' }}>Quick Links</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {pages.map((page, index) => (
              <li key={index} style={{ marginBottom: '10px' }}>
                <Link 
                  to={pagesRoutes[page]} 
                  style={{ textDecoration: 'none', fontWeight: '600', color: 'grey' }} 
                  onMouseEnter={(e) => { e.target.style.color = '#d6d6d6'; }} 
                  onMouseLeave={(e) => { e.target.style.color = 'grey'; }}
                >
                <img src={RightArrow} alt="Arrow" style={{ width: '15px', marginRight: '8px', color: '#530068' }} />
                  {page}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* 3rd column */}
        <div>
          <h4 style={{ marginBottom: '10px' }}>Our Services</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {services.map((service, index) => (
              <li key={index} style={{ marginBottom: '10px' }}>
                <Link 
                  to={servicesRoutes[service]} 
                  style={{ textDecoration: 'none', fontWeight: '600', color: 'grey' }} 
                  onMouseEnter={(e) => { e.target.style.color = '#d6d6d6'; }} 
                  onMouseLeave={(e) => { e.target.style.color = 'grey'; }}
                  >
                    <img src={RightArrow} alt="Arrow" style={{ width: '15px', marginRight: '8px', color: '#530068' }} />
                    {service}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* 2nd row or section */}
        <div
          style={{
            backgroundColor: 'black', // complete black
            color: 'white',
            padding: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}
        >
          <p style={{ margin: 0, marginLeft: '50px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', color: 'grey' }}>Copyright © 2024 <a href='/' style={{ textDecoration: 'none', color: '#d6d6d6' }} onMouseEnter={(e) => { e.target.style.color = '#d6d6d6'; }} onMouseLeave={(e) => { e.target.style.color = 'grey'; }}>Top Media Marketing </a></p>
          <div style={{ marginRight: '50px' }}>
            <IconButton aria-label="facebook" sx={iconButtonStyle} onClick={() => handleButtonClick('facebook')}>
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="linkedin" sx={iconButtonStyle} onClick={() => handleButtonClick('linkedin')}>
              <LinkedInIcon />
            </IconButton>
            <IconButton aria-label="instagram" sx={iconButtonStyle} onClick={() => handleButtonClick('instagram')}>
              <InstagramIcon />
            </IconButton>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;
