import React from "react";
import '../styles/AboutUsContainer.css';

export default function AboutUsContainer() {
    return (
        <div className="services">
             <div className="about-hero">
                <h2>Why Choose Us ?</h2>
            </div>
    
            <div className="services-main-content">
            <div className="left-content">
                <h1>EMPOWERING EFFICIENCY, ELEVATING SUCCESS</h1>
                <h2>We're your virtual ally in conquering tasks and reclaiming time. Let us handle the mundane while you focus on the extraordinary.</h2>
            </div>
            <div className="right-content">
                <p style={{
                    textAlign: 'left'
                }}>At Top Media Marketing, we understand the intricacies of real estate marketing and the value of quality customer interactions. With over a decade of experience, we offer not just services but solutions that drive growth, enhance productivity, and ensure your business's success in the competitive landscape.</p>
                <ul className="services-list">
                    <li>Acquisition Excellence: Unlock growth potential with our strategic acquisition solutions, tailored to identify opportunities and drive business expansion.</li>
                    <li>Virtual Assistant Support: Delegate tasks confidently to our skilled virtual assistants, ensuring your focus remains on core business activities while enjoying enhanced productivity.</li>
                    <li>Dynamic Marketing Strategies: Let us amplify your brand's reach through innovative marketing techniques, attracting and engaging your target audience for sustainable business growth.</li>
                    <li>Customer-Centric Care: Elevate customer satisfaction with our personalized customer care solutions, building lasting relationships and fostering loyalty through exceptional support.</li>
                </ul>
            </div>
            </div>
        </div>
    );
}