import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import user1 from "../assets/user1.jpeg";
import user2 from "../assets/user2.jpeg";
import user3 from "../assets/user3.jpeg";
import user4 from "../assets/user4.jpeg";
import user5 from "../assets/user5.jpeg";
import user6 from "../assets/user6.jpeg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export default function Reviews() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    return (
        <Box sx={{ padding: "0px 0px 30px 0px" }}>
            <Box sx={{ width: "90%", margin: "auto" }}>
                <Box>
                    <Typography
                        sx={{
                            fontSize: {xs:"30px" , md:"48px"},
                            fontWeight: "600",
                            background: `linear-gradient(to right, #530068, black)`,
                            WebkitBackgroundClip: "text",
                            color: "transparent",
                            lineHeight: "1.2",
                            textAlign: "center",
                            paddingBottom: "20px",
                        }}
                    >
                        {" "}
            What Our Clients Say
          </Typography>
                </Box>

                <Box>
                    <Box sx={{ paddingBottom: "40px" }}>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={true}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            customTransition="transform 500ms ease-in-out"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            dotListClass="custom-dot-list-style"
                        // itemClass="carousel-item-padding-40-px"
                        >
                            <div>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#530068",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            height: { xs: "auto", lg: "290px" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <FormatQuoteIcon
                                                style={{
                                                    color: "black",
                                                    position: "relative",
                                                    top: "2px",
                                                    left: "2px",
                                                    fontSize: "36px",
                                                }}
                                            />
                                        </Box>
                                        <Typography sx={{ paddingTop: "20px", color: "white" }}>"Incredible Results, Guaranteed Excellence!"</Typography>
                                        <Typography sx={{ color: "white" }}>
                                            "Working with Top Media Marketing has revolutionized the
                                            way I approach sales. Their team's expertise in targeted
                                            marketing strategies has significantly increased my client
                                            base and helped me close more deals than ever before. The
                                            quality of leads has been exceptional, leading to more
                                            efficient and effective sales processes. I highly
                                            recommend their services to any real estate professional
                                            looking to elevate their business."
                    </Typography>
                                    </Box>
                                    <Box sx={{ padding: "30px 20px 20px 20px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                                <Box>
                                                    <img
                                                        src={user1}
                                                        alt=""
                                                        style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "3px solid #530068",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "600",
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    Michael Thompson
                        </Typography>
                                                <Typography>
                                                    Real Estate Broker, Thompson Realty Group
                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#530068",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            height: { xs: "auto", lg: "290px" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <FormatQuoteIcon
                                                style={{
                                                    color: "black",
                                                    position: "relative",
                                                    top: "2px",
                                                    left: "2px",
                                                    fontSize: "36px",
                                                }}
                                            />
                                        </Box>
                                        <Typography sx={{ paddingTop: "20px", color: "white" }}>"Best in Marketing, Wished I'd Found Sooner!"</Typography>
                                        <Typography sx={{ color: "white" }}>
                                            "Top Media Marketing's team has been instrumental in
                                            boosting my real estate sales. Their innovative approach
                                            to digital marketing has resulted in a substantial
                                            increase in high-quality leads, making my job as a Realtor
                                            much easier and more profitable. The team is
                                            knowledgeable, professional, and always willing to go the
                                            extra mile. I'm thrilled with the results and look forward
                                            to continued success with their support."
                    </Typography>
                                    </Box>
                                    <Box sx={{ padding: "30px 20px 20px 20px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                                <Box>
                                                    <img
                                                        src={user2}
                                                        alt=""
                                                        style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "3px solid #530068",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "600",
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    Sarah Martinez
                        </Typography>
                                                <Typography>Realtor, Sunshine Properties</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#530068",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            height: { xs: "auto", lg: "290px" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <FormatQuoteIcon
                                                style={{
                                                    color: "black",
                                                    position: "relative",
                                                    top: "2px",
                                                    left: "2px",
                                                    fontSize: "36px",
                                                }}
                                            />
                                        </Box>
                                        <Typography sx={{ paddingTop: "20px", color: "white" }}>"Outstanding Agency, Immediate Impact."</Typography>

                                        <Typography sx={{ color: "white" }}>
                                            "Since partnering with Top Media Marketing, my sales have
                                            soared, and the leads I receive are far more qualified and
                                            ready to act. The team's understanding of the real estate
                                            market and how to leverage digital platforms for maximum
                                            impact is impressive. Their personalized service and
                                            dedication to my success have made a significant
                                            difference in my career as a real estate agent."
                    </Typography>
                                    </Box>
                                    <Box sx={{ padding: "30px 20px 20px 20px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                                <Box>
                                                    <img
                                                        src={user3}
                                                        alt=""
                                                        style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "3px solid #530068",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "600",
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    David Kim
                        </Typography>
                                                <Typography>
                                                    Real Estate Agent, Metro Homes Realty
                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </div>

                            <div>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#530068",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            height: { xs: "auto", lg: "290px" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <FormatQuoteIcon
                                                style={{
                                                    color: "black",
                                                    position: "relative",
                                                    top: "2px",
                                                    left: "2px",
                                                    fontSize: "36px",
                                                }}
                                            />
                                        </Box>
                                        <Typography sx={{ paddingTop: "20px", color: "white" }}>"Top-Notch Marketing, Should've Hired Sooner!"</Typography>

                                        <Typography sx={{ color: "white" }}>
                                            "Top Media Marketing has been a key factor in my
                                            brokerage's growth. Their targeted marketing strategies
                                            have brought in high-quality leads, which has directly
                                            translated into increased sales and revenue. The team's
                                            professionalism and expertise in the real estate market
                                            are exceptional. They understand our needs and
                                            consistently deliver results that exceed our
                                            expectations."
                    </Typography>
                                    </Box>
                                    <Box sx={{ padding: "30px 20px 20px 20px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                                <Box>
                                                    <img
                                                        src={user4}
                                                        alt=""
                                                        style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "3px solid #530068",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "600",
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    Emily Johnson
                        </Typography>
                                                <Typography>
                                                    Broker-Owner, Johnson Elite Realty
                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#530068",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            height: { xs: "auto", lg: "290px" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <FormatQuoteIcon
                                                style={{
                                                    color: "black",
                                                    position: "relative",
                                                    top: "2px",
                                                    left: "2px",
                                                    fontSize: "36px",
                                                }}
                                            />
                                        </Box>
                                        <Typography sx={{ paddingTop: "20px", color: "white" }}>"Exceptional Results, No Regrets!"</Typography>

                                        <Typography sx={{ color: "white" }}>
                                            "I'm amazed by the results I've seen since working with
                                            Top Media Marketing. Their tailored marketing strategies
                                            have not only increased my sales but also improved the
                                            quality of my leads. The team is responsive,
                                            knowledgeable, and always up-to-date with the latest
                                            marketing trends, which is crucial in the competitive real
                                            estate industry. I highly recommend them to any agent
                                            looking to boost their sales and online presence."
                    </Typography>
                                    </Box>
                                    <Box sx={{ padding: "30px 20px 20px 20px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                                <Box>
                                                    <img
                                                        src={user5}
                                                        alt=""
                                                        style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "3px solid #530068",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "600",
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    Alex Rodriguez,
                        </Typography>
                                                <Typography>
                                                    Real Estate Agent, Coastal Living Realty
                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#530068",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            height: { xs: "auto", lg: "290px" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <FormatQuoteIcon
                                                style={{
                                                    color: "black",
                                                    position: "relative",
                                                    top: "2px",
                                                    left: "2px",
                                                    fontSize: "36px",
                                                }}
                                            />
                                        </Box>
                                        <Typography sx={{ paddingTop: "20px", color: "white" }}>"Marketing Mastery, A Missed Opportunity Until Now."</Typography>

                                        <Typography sx={{ color: "white" }}>
                                            "Top Media Marketing has been a fantastic partner for my
                                            real estate business. Their strategic approach to
                                            marketing has significantly increased both my sales and
                                            the caliber of my leads. The team's expertise in digital
                                            advertising and understanding of the real estate market is
                                            remarkable. They are dedicated, results-oriented, and
                                            genuinely invested in my success. I couldn't be happier
                                            with the results."
                    </Typography>
                                    </Box>
                                    <Box sx={{ padding: "30px 20px 20px 20px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                                <Box>
                                                    <img
                                                        src={user6}
                                                        alt=""
                                                        style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "3px solid #530068",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "600",
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    Lisa Chang
                        </Typography>
                                                <Typography>
                                                    Broker Associate, Prestige Properties
                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </div>
                        </Carousel>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
