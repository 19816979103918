import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import blog2 from "../assets/blog2.jpeg";

const Blog3 = () => {
    return (
        <Box sx={{ backgroundColor: "", padding: "53px 0px" }}>

            <Box sx={{ width: "90%", margin: "auto" }}>
                <Box sx={{ marginBottom: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                            <Typography sx={{ fontWeight: "600" }}>Does Digital Marketing Work for Real Estate?</Typography>
                            <Typography sx={{ fontWeight: "400", color: "grey" }}>Posted a few days ago</Typography>
                            <Typography sx={{ fontWeight: "400", paddingBottom: "10px", paddingTop: "10px" }}>
                                Digital marketing is not just working for real estate—it's transforming it. In a world where over 44% of home buyers start their search online, having a strong digital presence is critical. Real estate professionals are leveraging the power of the internet to reach more potential clients than ever before.

                                Targeted Social Media Campaigns
                                Social media platforms are a goldmine for real estate professionals. By using targeted ads on platforms like Facebook and Instagram, agents can place their listings in front of the right audience, based on demographics, interests, and behaviors. Moreover, engaging content on these platforms can help build a community around a brand and generate word-of-mouth referrals.

                                SEO for Real Estate
                                Search engine optimization is crucial for real estate websites. Digital marketing agencies can optimize a site's content to rank higher for relevant keywords, making it more likely that potential clients will find the listings when they search for properties online. SEO strategies include on-page optimization, local SEO for reaching clients in specific geographic areas, and technical SEO to improve website performance.

                                Email Marketing Campaigns
                                Email marketing remains one of the most effective digital marketing strategies, particularly for real estate. A well-crafted email campaign can nurture leads by providing them with valuable information, such as market trends, new listings, and open house announcements. Digital marketing agencies can help design and automate these campaigns, ensuring consistent touchpoints with potential clients.

                                Analytics and Reporting
                                One of the most significant advantages of digital marketing is the ability to track and measure the effectiveness of each campaign. Digital marketing agencies use advanced analytics tools to provide insights into the performance of marketing efforts. This data-driven approach enables real estate professionals to make informed decisions and invest in the most effective marketing strategies.

                                In conclusion, digital marketing does more than just work for real estate—it is an essential component of a successful real estate business in today's digital world. With the right digital marketing strategies, real estate professionals can reach wider audiences, create engaging experiences for potential buyers, and ultimately close more deals.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img src={blog2} alt="" style={{ width: "100%", objectFit: "contain" }} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box >
    )
}

export default Blog3