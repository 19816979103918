import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Link } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const AnimatedBox = animated(Box);

export default function AboutAgency() {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });
    const [count, setCount] = useState(0);
    const [countOne, setCountOne] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (count < 471) {
                setCount(count + 1);
            } else {
                clearInterval(intervalId);
            }
        }, 4);
        return () => clearInterval(intervalId);
    }, [count]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (countOne < 5) {
                setCountOne(countOne + 1);
            } else {
                clearInterval(intervalId);
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countOne]);

    const fadeInUpAnimation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s, transform 0.5s",
    });

    return (
        <Box sx={{ padding: "40px 0px 100px 0px" }}>
            <AnimatedBox ref={ref} style={fadeInUpAnimation}>
                <Box sx={{ width: "90%", margin: "auto" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{ opacity: inView ? 1 : 0, transition: "opacity 0.5s" }}>
                                <Typography sx={{ fontSize: {xs:"25px" , marginBottom:"10px", md:"42px"}, fontWeight: "300" }}>
                                    Trusted Worldwide
                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: {xs:"30px" , md:"56px"},
                                        fontWeight: "700",
                                        marginBottom:"10px",
                                        background: `linear-gradient(to right, #530068, black)`,
                                        WebkitBackgroundClip: "text",
                                        color: "transparent",
                                        lineHeight: "1.2",
                                    }}
                                >
                                    Here's why Real Estate Professionals love us
                </Typography>
                                <Typography sx={{ fontSize: {xs:"18px" , md:"22px"}, mt: 2 }}>
                                    In 2023, we proudly helped over 471 Realtors just like you! Our
                                    team grew to 71 professionals across 4 international offices,
                                    highlighting our commitment to global expansion and exceptional
                                    service.
                </Typography>
                                <Box sx={{ mt: 3 }}>
                                    <Link
                                        href="https://calendly.com/toprealtorslimited/free-consultation"
                                        sx={{
                                            fontSize: "26px",
                                            fontWeight: "600",
                                            color: "#0077b6",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                    >
                                        What are you waiting for?
                  </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box sx={{ borderBottom: "3px solid #00b0e5" }}>
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: "grey",
                                                    pb: 2,
                                                }}
                                            >
                                                Real Estate Professionals helped in 2023
                      </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: {xs:"80px" , md:"160px"},
                                                    fontWeight: '700',
                                                    color: '#530068',
                                                    lineHeight: '1.1',
                                                    marginTop:"10px",
                                                }}
                                            >
                                                {count}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box sx={{ borderBottom: "3px solid #00b0e5" }}>
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: "grey",
                                                    pb: 2,
                                                }}
                                            >
                                                International Offices
                      </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: {xs:"80px" , md:"160px"},
                                                    fontWeight: "700",
                                                    color: "#530068",
                                                    lineHeight: "1.1",
                                                    marginTop:"10px",
                                                }}
                                            >
                                                {countOne}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </AnimatedBox>
        </Box>
    );
}
