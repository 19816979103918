import { Typography, Box, Grid, Button, Link } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import customerSupportImage from "../assets/customer-support-service.jpeg";

const AnimatedBox = animated(Box);

export default function CusstomerSupportService() {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });

    const fadeInUpAnimation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s, transform 0.5s",
    });

    return (
        <Box sx={{ padding: "20px", marginTop: "20px", marginBottom: "40px" }}>
            <Box sx={{ width: "90%", margin: "auto", paddingTop: "40px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ref={ref}>
                        <AnimatedBox style={fadeInUpAnimation}>
                            <Typography
                                sx={{
                                    fontSize: { xs: "30px", md: "48px" },
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                    background: `linear-gradient(to right, #530068, black)`,
                                    WebkitBackgroundClip: "text",
                                    color: "transparent",
                                    lineHeight: "1.2",
                                }}
                            >
                                Customer Support Services
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "16px", md: "20px" }, }}>
                                We extend our call center excellence to provide top-tier customer support representatives to large companies, offering significant savings and efficiency improvements.
                            </Typography>
                            <Box sx={{ padding: "30px 0px" }}>
                            </Box>
                            <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                                <Button
                                    type="danger"
                                    sx={{
                                        backgroundColor: "#530068",
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "15px 30px",
                                        ':hover': {
                                            backgroundColor: "#800080 !important",
                                        },
                                    }}
                                >
                                    Join over 400 Realtors that trust us!
                                </Button>
                            </Link>
                        </AnimatedBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box>
                            <img
                                src={customerSupportImage}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "540px",
                                    objectFit: "contain", // or "contain" depending on your preference
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
