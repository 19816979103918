import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrivacyPolicySection from "../components/PrivacyPolicySection";

export default function PrivacyPolicy() {
    return (
        <Box>
            <Header />
            <PrivacyPolicySection />
            <Footer />
        </Box>

    );
}
