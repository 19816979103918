import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, Button, Box, Modal, IconButton } from '@mui/material';
import headerSectionImage from "../assets/pricing-hero.jpg"
import MailIcon from '@mui/icons-material/Mail';

const pricingOptions = [
  {
    title: 'Part Time',
    description: 'For small teams wanting to try out a Virtual Assistant for the first time.',
    features: [
      'Background Check',
      'Dedicated VA',
      'Weekly Progress Report',
      'Onboarding Support',
      'Account Manager',
      '24/7 service',
      'Manager Accessibility',
    ],
    buttonVariant: 'outlined',
  },
  {
    title: 'Full time',
    description: 'For established teams wanting to expand operation and lower their overhead cost.',
    features: [
      'Background Check',
      'Dedicated VA',
      'Weekly Progress Report',
      'Onboarding Support',
      'Account Manager',
      '24/7 service',
      'Manager Accessibility',
    ],
    buttonVariant: 'contained',
  },
  {
    title: 'Acquisition',
    description: 'For running comparable analysis, presenting offers and closings.',
    features: [
      'Background Check',
      'Dedicated VA',
      'Weekly Progress Report',
      'Onboarding Support',
      'Account Manager',
      '24/7 service',
      'Manager Accessibility',
    ],
    buttonVariant: 'outlined',
  },
];

const PricingCard = ({ option, handleOpenModal }) => (
  <Card sx={{
    maxWidth: 345,
    m: 2,
    boxShadow: 3,
    padding: '20px',
    transition: 'box-shadow 0.3s ease', // Add transition for hover effect
    '&:hover': {
      boxShadow: 5, // Increase the shadow on hover
      transform: 'translateY(-5px)', // Move the card slightly forward on hover
    },
  }}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="div" textAlign="center" color="#530068">
        {option.title}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary" textAlign="center">
        {option.description}
      </Typography>
      <Typography variant="h6" component="div" textAlign="center">
        What you get
      </Typography>
      {option.features.map((feature, index) => (
        <Typography key={index} variant="body2" textAlign="center">
          {feature}
        </Typography>
      ))}
    </CardContent>
    <CardActions sx={{ justifyContent: 'center' }}>
      <Button size="large" variant={option.buttonVariant} color="primary"
        sx={{
          backgroundColor: "#530068",
          fontWeight: "bold",
          textTransform: "capitalize",
          color: "white",
          borderRadius: "10px",
          padding: "15px 30px",
          '&:hover': {
            backgroundColor: "#800080",
          },
        }}
        onClick={() => handleOpenModal('khalid@topmediamarketing.ca')}
      >
        Contact Us
      </Button>
    </CardActions>
  </Card>
);

const PricingSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');

  const handleOpenModal = (email) => {
    setOpenModal(true);
    setSelectedEmail(email);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div style={{
        width: '100%',
        height: '250px',
        backgroundImage: `url(${headerSectionImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <h2
          style={{
            color: '#ffffff',
            fontSize: '2.5rem',
            textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)'
          }}
        >Grab the Best Deals</h2>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap', // Allow the items to wrap as needed
          justifyContent: 'center',
          gap: 2, // This creates a gap between the cards
          mt: 4,
          padding: '20px',
          mb: '20px',
        }}
      >
        {pricingOptions.map((option, index) => (
          <Box
            key={index}
            sx={{
              maxWidth: 345,
              flexBasis: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33.333% - 16px)' }, // Adjust card width minus the gap for 2 or 3 column layout
              mb: 2, // Margin bottom for when they wrap
            }}
          >
            <PricingCard option={option} handleOpenModal={handleOpenModal} />
          </Box>
        ))}
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '1px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center'
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Contact Us
          </Typography>
          <IconButton aria-label="send email" onClick={() => window.location.href = `mailto:${selectedEmail}`}>
            <MailIcon style={{ marginRight: '10px', textDecoration: 'none', background: 'transparent', backgroundColor: 'inherit'}} /> <p>{selectedEmail} </p>
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default PricingSection;