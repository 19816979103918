import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeaderSection from "../components/HeaderSection";
import AboutAgency from "../components/AboutAgency";
import DigitalWork from "../components/DigitalWork"
// import Projects from "../components/Projects";
import Companies from "../components/Companies";
// import Industries from "../components/Industries";
import Ecosystem from "../components/Ecosystem";
import AmazingPeople from "../components/AmazingPeople";
import Blogs from "../components/Blogs";
import Reviews from "../components/Reviews";


export default function Home() {
    return (
        <Box>
            <Header />
            <HeaderSection />
            <Ecosystem />
            <DigitalWork />
            <Companies />
            <AboutAgency />

            {/* <Projects /> */}
            {/* <Industries /> */}
            <AmazingPeople />
            <Blogs />
            <Reviews />
            <Footer />
        </Box>

    );
}
