import React from "react";
import { Typography, Box, Grid, Button, Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import callCenterImage from "../assets/call-center-service.jpeg";

const AnimatedBox = animated(Box);

export default function CallCenterAndCallingService() {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });

    const fadeInUpAnimation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s, transform 0.5s",
    });

    return (
        <Box sx={{ padding: "20px", marginTop: "20px", marginBottom: "40px" }}>
            <Box sx={{ width: "90%", margin: "auto", paddingTop: "40px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ref={ref}>
                        <AnimatedBox style={fadeInUpAnimation}>
                            <Typography
                                sx={{
                                    fontSize: { xs: "30px", md: "48px" },
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                    background: `linear-gradient(to right, #530068, black)`,
                                    WebkitBackgroundClip: "text",
                                    color: "transparent",
                                    lineHeight: "1.2",
                                }}
                            >
                                Call Center and Calling
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "16px", md: "20px" }, }}>
                                In the competitive real estate industry, cold calling remains a powerful tool for lead generation. Our professional team excels in generating quality leads, appointment setting, market research, and database management, ensuring your business stays ahead. We have experienced team and fast work flow.
                            </Typography>
                            <Box sx={{ padding: "30px 0px" }}>
                                <h3 style={{
                                    color: "#530068"
                                }}>Experienced in:</h3>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* Checklist Item 1 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Insurance Calls
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 2 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Real Estate
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 3 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Funding Loan
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Vending Machine Placement
                                            </Typography>
                                        </Box>


                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Digital Marketing
                                            </Typography>
                                        </Box>


                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Cleaning Services
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Dispatch Sevices
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                PPE Company
                                            </Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Mortgage
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Home Improvement
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 5 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Auto Insurance
                                            </Typography>
                                        </Box>
                                        {/* Checklist Item 6 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Final Expense
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Medicare
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Solar
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Several Other B2B and B2C Campaigns
                                            </Typography>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                            <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                                <Button
                                    type="danger"
                                    sx={{
                                        backgroundColor: "#530068",
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "15px 30px",
                                        ':hover': {
                                            backgroundColor: "#800080 !important",
                                        },
                                    }}
                                >
                                    Join over 400 Realtors that trust us!
                                </Button>
                            </Link>
                        </AnimatedBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box>
                            <img
                                src={callCenterImage}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "540px",
                                    objectFit: "contain", // or "contain" depending on your preference
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
