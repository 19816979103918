import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import blog3 from "../assets/blog3.jpeg";

const Blog1 = () => {
    return (
        <Box sx={{ backgroundColor: "", padding: "67px 0px" }}>

            <Box sx={{ width: "90%", margin: "auto" }}>
                <Box sx={{ marginBottom: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                            <Typography sx={{ fontWeight: "600" }}>What is a Digital Marketing Agency?</Typography>
                            <Typography sx={{ fontWeight: "400", color: "grey" }}>Posted last week</Typography>
                            <Typography sx={{ fontWeight: "400", paddingBottom: "10px", paddingTop: "10px" }}>
                                Digital marketing agencies are modern-day navigators of the vast online marketplace. Their role is to guide businesses through the complex digital landscape, ensuring their brand's online presence is strong, consistent, and engaging. These agencies are powerhouses of expertise in various online marketing strategies, including:

                                Search Engine Optimization (SEO): Crafting websites and content to rank higher in search engine results, making businesses more discoverable to potential customers.
                                Content Marketing: Developing relevant and valuable content to attract and retain a clearly defined audience, positioning businesses as industry leaders.
                                Social Media Marketing: Utilizing platforms like Facebook, Instagram, and LinkedIn to build communities around brands, enhance customer engagement, and drive traffic and sales.
                                Pay-Per-Click (PPC) Advertising: Implementing paid ads on search engines and social media, providing immediate visibility and targeted reach.
                                Email Marketing: Sending personalized, content-rich emails to a business's subscriber list to inform, engage, and convert recipients.
                                Analytics and Data Analysis: Using data to analyze marketing efforts' effectiveness, driving decision-making and strategy adjustments.
                                By integrating these strategies, digital marketing agencies help businesses navigate the digital domain, connecting with audiences in impactful ways, ultimately driving growth and success in the digital age.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img src={blog3} alt="" style={{ width: "100%", objectFit: "contain" }} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box >
    )
}

export default Blog1