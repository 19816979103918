import React from "react";
import { Typography, Box, Grid, Button, Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import digitalWork1 from "../assets/realEstate.png";

const AnimatedBox = animated(Box);

export default function DigitalWork() {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });

    const fadeInUpAnimation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s, transform 0.5s",
    });

    return (
        <Box sx={{ padding: "0px  0px 20px 0px", marginBottom: "40px" }}>
            <Box sx={{ width: "90%", margin: "auto", paddingTop: "40px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ref={ref}>
                        <AnimatedBox style={fadeInUpAnimation}>
                            <Typography sx={{ fontSize: {xs:"25px" , md:"36px" , marginBottom:"10px"}, fontWeight: "200" }}>
                                Trusted by
              </Typography>
                            <Typography
                                sx={{
                                    fontSize: {xs:"30px" , md:"48px"},
                                    fontWeight: "600",
                                    marginBottom:"10px",
                                    background: `linear-gradient(to right, #530068, black)`,
                                    WebkitBackgroundClip: "text",
                                    color: "transparent",
                                    lineHeight: "1.2",
                                }}
                            >
                                Real Estate Agents, Brokers and Developers
              </Typography>
                            <Typography sx={{ fontSize:{xs:"16px" , md:"20px"}, }}>
                                Since 2003, we’ve built successful partnerships by exceeding our
                                clients’ expectations. Our mission is to help them achieve their
                                goals and share their message with the world.
              </Typography>
                            <Box sx={{ padding: "30px 0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* Checklist Item 1 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Increase leads and sales
                      </Typography>
                                        </Box>
                                        {/* Checklist Item 2 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Increase brand impact
                      </Typography>
                                        </Box>
                                        {/* Checklist Item 3 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Optimize your social presence
                      </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* Checklist Item 4 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Maximize engagement
                      </Typography>
                                        </Box>
                                        {/* Checklist Item 5 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Boost social media presence
                      </Typography>
                                        </Box>
                                        {/* Checklist Item 6 */}
                                        <Box sx={{ display: "flex", margin: "10px 0px" }}>
                                            <CheckCircleIcon style={{ color: "#530068" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                Increase brand awareness
                      </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                                <Button
                                    type="danger"
                                    sx={{
                                        backgroundColor: "#530068",
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "15px 30px",
                                    }}
                                >
                                    Join over 400 Realtors that trust us!
                </Button>
                            </Link>
                        </AnimatedBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box>
                            <img
                                src={digitalWork1}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "540px",
                                    objectFit: "contain", // or "contain" depending on your preference
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
