import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container style={{padding: '50px'}}>
      <Typography variant="h4" style={{ color: '#530068', marginTop: '30px', marginBottom: '50px', textAlign: 'center' }}>
        Privacy Policy - What Information We Collect & How We Process It
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        At Top Media Marketing, we are committed to protecting your privacy and ensuring that your personal information is handled in a secure and responsible manner. This privacy policy explains how we collect, use, and disclose personal information when you use our website, products, or services.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Information We Collect
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        We collect information from you when you subscribe to our newsletter, fill out a form, or submit property information.
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        We may collect information about your computer, including your IP address, operating system, and browser type using Google Analytics. This is to improve browsing for everyone and does not identify any individual. You can learn more about how data is collected with Analytics here.
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, or phone number. You may, however, visit our site anonymously. We transfer information about you if Top Media Marketing is acquired by or merged with another company. In this event, Top Media Marketing will notify you before information about you is transferred and becomes subject to a different privacy policy.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        What do we use your information for?
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        Any of the information we collect from you may be used in one of the following ways:
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        To process transactions
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        To send periodic emails
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        The email address you provide may be used to send you information, respond to inquiries, and/or other requests or questions.
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        To send regular marketing and follow-up campaign text updates
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        The phone number you provide may be used to send you updates, ask additional information, send property valuation, inquiries, and/or other requests in relation to buying/selling your properties with us. The quantity of our text varies.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        How do we protect your information?
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Do we disclose any information to outside parties?
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Third party links
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        California Online Privacy Protection Act Compliance
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We, therefore, will not distribute your personal information to outside parties without your consent.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Children’s Online Privacy Protection Act Compliance
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products, and services are all directed to people who are at least 13 years old or older.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Personal, Financial, And Property Information
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        Our company is a real estate services firm that may require you submitting certain information so we can help you in your situation. This information may include your contact info, information about your property, or even in some cases financial information needed to help us analyze your property and situation. This information is for our use only and will not be shared with outside parties unless as required to help us help you solve your real estate situation.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Online Privacy Policy Only
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        This online privacy policy applies only to information collected through our website and not to information collected offline.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Changes to our Privacy Policy
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        If we decide to change our privacy policy, we will post those changes on this page.
      </Typography>
      <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>
        Contacting Us
      </Typography>
      <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
        If there are any questions regarding this privacy policy you may contact us with the information on our contact page.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;