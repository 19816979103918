import React from "react";
import '../styles/AboutUsContainer.css';
import { useInView } from 'react-intersection-observer';

import { Button, Link } from "@mui/material";
import { styled } from '@mui/system';
import { keyframes } from '@mui/system';

// Define a keyframe animation for the fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Create a styled component for the animated button
const AnimatedButton = styled(Button)({
    animation: `${fadeIn} 0.5s ease-out`,
});


export default function ContactUsContainer() {
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger animation only once when in view
    });
    return (
        <div className="services">
            <div className="about-hero">
                <h2>Contact Us</h2>
            </div>

            <div className="services-main-content">
                <div className="left-content">
                    <h2>Contact Us Via email</h2>
                    <p>khalid@topmediamarketing.ca</p>
                </div>
                <div className="right-content">
                    <h2 style={{
                        textAlign: 'left'
                    }}>Schedule your 1 on 1 call now</h2>
                    <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                        <AnimatedButton
                            variant="contained"
                            sx={{
                                backgroundColor: "#530068",
                                color: "white",
                                fontWeight: "600",
                                fontSize: { xs: "14px", md: "16px" },
                                marginTop: "20px",
                                marginBottom: { xs: "80px", md: "150px" },
                                padding: "8px 20px",
                                // Apply the fade-in animation when the button is in view
                                opacity: inView ? 1 : 0,
                                transform: inView ? 'translateY(0)' : 'translateY(20px)',
                            }}
                            ref={ref}
                        >
                            Schedule Your FREE 1-on-1 Call Now!
                        </AnimatedButton>
                    </Link>
                </div>
            </div>
        </div>
    );
}