import React from "react";
import { Box } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Companies() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <Box sx={{ paddingBottom: "40px", backgroundColor: "#f7f7f7" }}>

            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            >
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>
                        <img src="./coldwell banker logo.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>  <img src="./compass.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>   <img src="./download-removebg-preview (1).png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>  <img src="./exp.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>    <img src="./download-removebg-preview.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>    <img src="./localimagereader.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>    <img src="./sotheby's.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>    <img src="./800px-Keller_Williams_Realty_logo.svg.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>   <img src="./REMAX_logo.svg.png" alt="" style={{ width: "100%" }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>     <img src="./CBRE_Group-Logo.wine.png" alt="" style={{ width: "100%" }} />
                    </Box>

                </div>
                <div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '180px',
                    }}>
                        <img src="./weichert.png" alt="" style={{ width: "100%" }} />
                    </Box>

                </div>
            </Carousel>
        </Box>
    );
}
