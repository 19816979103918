import React, { useState, useRef } from 'react';
import { Typography, Box, Grid, Button, Link } from "@mui/material";
import topMedia2 from "../assets/topMedia2.png";
import award1 from "../assets/award1.png";
import award2 from "../assets/award2.png";
import award3 from "../assets/award3.png";
import sectionVideo from "../assets/sectionVideo.mp4";

export default function AmazingPeople() {
    const [isPlaying, setIsPlaying] = useState(true);
    const videoRef = useRef(null);

    const togglePlay = () => {
        const video = videoRef.current;

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }

        setIsPlaying(!isPlaying);
    };
    return (
        <Box
            sx={{
                backgroundImage: `url(${topMedia2})`, // Add the background image here
                backgroundSize: "cover", // Adjust as needed
                backgroundRepeat: "no-repeat", // Adjust as needed
                backgroundPosition: "center", // Adjust as needed
                paddingTop: "100px",
                paddingBottom: "100px",
            }}
        >
            <Box sx={{ width: "90%", margin: "auto" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: { xs: "30px", md: "48px" },
                                    color: "white",
                                    lineHeight: "1.2",
                                    textAlign: { xs: "center", md: "start" },
                                }}
                            >
                                We do the heavy lifting!{" "}
                                <span style={{ fontWeight: "400", fontSize: "36px" }}></span>
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: "400",
                                    fontSize: { xs: "18px", md: "20px" },
                                    color: "white",
                                    paddingTop: "20px",
                                    textAlign: { xs: "center", md: "start" },
                                }}
                            >
                                By harnessing the power of AI, combined with our team of
                                marketing experts and real estate veterans, we create
                                tailor-made campaigns that hit the mark every time. We
                                meticulously analyze successful strategies and decipher
                                algorithms to ensure your listings multiply every month. Get
                                ready to embrace the lifestyle you've always dreamt of – that
                                luxurious car and dream home are now within your reach!
                            </Typography>

                            <Box sx={{
                                marginTop: "20px", display: { xs: "flex", md: "block" },
                                justifyContent: "center",
                                alighItems: { xs: "center", md: "center" },
                            }}>
                                <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                                    <Button
                                        type="danger"
                                        sx={{
                                            backgroundColor: "#530068",
                                            fontWeight: "bold",
                                            color: "white",
                                            borderRadius: "10px",
                                            padding: "15px 30px",
                                        }}
                                    >
                                        I'm ready to get more clients!
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                                sx={{
                                    width: { xs: '100%', sm: '200%' },
                                    height: '100%',
                                }}
                            >
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    controls  // This adds play, pause, and audio controls
                                    ref={videoRef}
                                    onClick={togglePlay}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                >
                                    <source src={sectionVideo} type="video/mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ textAlign: "center", margin: { xs: "0px 0px", lg: "40px 100px" } }}>
                    <Typography
                        sx={{
                            fontWeight: {xs:"500" , md:"600"},
                            fontSize: {xs:"20px" , md:"24px"},
                            color: "white",
                            lineHeight: {xs:"1.3" , md:"1.2"},
                            textAlign:{xs:"center" , md:"start"},
                            margin:"10px"
                        }}
                    >
                        "Simply the best to work with, I've wasted thousands on other
                        agencies whom failed to deliver results, but Top Media Marketing
                        nearly got me more deals in 3 months than I did in the previous 2
                        years, and I've been doing this for 12 years!"{" "}
                        <span style={{ fontWeight: "400", fontSize: "16px" }}>
                            - Michelle S., Real Estate Broker
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: "400",
                            fontSize: "20px",
                            color: "white",
                            paddingTop: "20px",
                        }}
                    ></Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    padding: "30px",
                                    borderRadius: "20px",
                                    height: "200px",
                                }}
                            >
                                <Grid container spacing={2} sx={{display:"flex" , alignItems:"center" ,}}>
                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <img src={award1} alt="" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography
                                            sx={{
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                paddingLeft: "10px",
                                                marginLeft:"10px",
                                            }}
                                        >
                                            Meta Ads
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography>
                                    In the crowded digital landscape, become the trusted realtor
                                    that stands out. Seize the opportunity with targeted Meta ads
                                    that capture attention and build your reputation.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    padding: "30px",
                                    borderRadius: "20px",
                                    height: "200px",
                                }}
                            >
                                <Grid container spacing={2} sx={{display:"flex" , alignItems:"center" ,}}>
                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <img src={award2} alt="" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography
                                            sx={{
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                paddingLeft: "10px",
                                                marginLeft:"10px",
                                            }}
                                        >
                                            Automation
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography>
                                    With our automated service, showcase your real estate
                                    expertise effortlessly while you relax and watch the leads
                                    roll in!
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    padding: "30px",
                                    borderRadius: "20px",
                                    height: "200px",
                                }}
                            >
                                <Grid container spacing={2} sx={{display:"flex" , alignItems:"center" ,}}>
                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <img src={award3} alt="" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography
                                            sx={{
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                paddingLeft: "10px",
                                                marginLeft:"10px",
                                            }}
                                        >
                                            Lead Qualification Service
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography>
                                    Elevate your time with our ISA team, ensuring only hot leads
                                    reach your calendar. Focus on closing deals, we'll handle the
                                    qualification process.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
