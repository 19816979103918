import React from "react";
import { Typography, Box, Button, Link } from "@mui/material";
import { useInView } from 'react-intersection-observer';
import bgVideo from "../assets/bgVideo.mp4";
import { styled } from '@mui/system';
import { keyframes } from '@mui/system';

// Define a keyframe animation for the fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Create a styled component for the animated button
const AnimatedButton = styled(Button)({
    animation: `${fadeIn} 0.5s ease-out`,
});


export default function HeaderSection() {
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger animation only once when in view
    });
    return (
        <Box
            sx={{
                position: "relative",
                overflow: "hidden",
            }}
        >
            <video
                autoPlay
                loop
                muted
                style={{

                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            >
                <source src={bgVideo} type="video/mp4" />

            </video>

            <Box sx={{ width: "90%", margin: "auto", position: "relative", zIndex: 1 }}>
                <Box sx={{ width: { xs: "100%", lg: "50%" } }}>
                    <Typography
                        sx={{
                            color: "white",
                            fontWeight: "600",
                            fontSize: {xs:"50px" , md:"72px"},
                            lineHeight: {xs:"1.2" , md:"1"},
                            paddingTop: {xs:"80px" , md:"150px"},
                        }}
                    >
                        Stand Out in the Digital Market
                    </Typography>

                    <Typography
                        sx={{
                            color: "white",
                            fontWeight: "500",
                            fontSize: {xs:"22px" , md:"36px"},
                            lineHeight: {xs:"1.5" , md:"1"},
                            paddingTop: "20px",
                        }}
                    >
                        Elevate Your Real Estate Business with Expert Social Media Marketing
                    </Typography>

                    <Link href="https://calendly.com/toprealtorslimited/free-consultation">
                        <AnimatedButton
                            variant="contained"
                            sx={{
                                backgroundColor: "#530068",
                                color: "white",
                                fontWeight: "600",
                                fontSize: {xs:"14px" , md:"16px"},
                                marginTop: "20px",
                                marginBottom: {xs:"80px" , md:"150px"},
                                padding: "8px 20px",
                                // Apply the fade-in animation when the button is in view
                                opacity: inView ? 1 : 0,
                                transform: inView ? 'translateY(0)' : 'translateY(20px)',
                            }}
                            ref={ref}
                        >
                            Schedule Your FREE 1-on-1 Call Now!
      </AnimatedButton>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}
